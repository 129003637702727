<template>
  <div class="myContract">
    <!--        导航-->
    <!-- 顶部固定区域-->

    <div class="header">
      <div class="potential">
        <rxNavBar title="我的合同" androidOrIOSFlag="true"></rxNavBar>
      </div>
      <!--        合同转换-->
      <!-- <div class="clientChange">
				<div class="clientTitle">
					<span :class="[potClient?'potClientOne':'potClientTwo']" @click="changeClientOne">租客合同</span>
					<span class="su">|</span>
					<span :class="[priClient?'priClientOne':'priClientTwo']" @click="changeClientTwo">业主合同</span>
				</div>
				<div :class="[last?'lasta':'lastb']"></div>
			</div> -->

      <div class="choose">
        <div class="choose-one">
          <div
            :class="condition===1 ? 'choose-one-textActive' : 'choose-one-text'"
            @click="changeClientOne"
          >
            租客合同
          </div>
          <div class="choose-one-icon" v-show="condition===1"></div>
        </div>
        <div class="choose-line"></div>
        <div class="choose-one">
          <div
            :class="condition===2 ? 'choose-one-textActive' : 'choose-one-text'"
            @click="changeClientTwo"
          >
            业主合同
          </div>
          <div class="choose-one-icon" v-show="condition===2"></div>
        </div>
        <div class="choose-line"></div>
        <div class="choose-one">
          <div
              :class="condition===3 ? 'choose-one-textActive' : 'choose-one-text'"
              @click="changeClientThree"
          >
            变更协议
          </div>
          <div class="choose-one-icon" v-show="condition===3"></div>
        </div>
      </div>
    </div>
    <div class="hTitle">
      <!--        合同==循环列表============================================================================-->

      <van-list v-if="condition!=3"
        v-model="loading"
        :finished="finished"
        :finished-text="noContent ? '' : ''"
        @load="this.onLoad"
      >
        <div class="circulation" v-for="(item, i) in contract" :key="i">
          <div
            :class="
              item.contractStatus == '7'
                ? circulationListStateGray
                : circulationListState
            "
            class="circulationList"
          >
            <div @click="showOwner({ item })">
              <!-- 预定 -->
              <div>
                <div v-if="item.contractStatus == '预定'">
                  <div class="stateOrange">
                    {{ item.contractStatus }}
                  </div>
                </div>
              </div>
              <div v-for="(items, i) in contractStatusMap" :key="i">
                <!-- 在租中 -->
                <div
                  v-if="
                    item.contractStatus != 4 &&
                      item.contractStatus != 5 &&
                      item.contractStatus != 7
                  "
                >
                  <div
                    class="stateGreen"
                    v-if="items.dictionaryValue == item.contractStatus"
                  >
                    {{ item.contractStatusVal }}
                  </div>
                </div>
                <!-- 代签字 -->
                <div v-if="item.contractStatus == 5">
                  <div
                    class="stateOrange"
                    v-if="items.dictionaryValue == item.contractStatus"
                  >
                    {{ item.contractStatusVal }}
                  </div>
                </div>

                <!-- 已作废 已作废 -->
                <div
                  v-if="item.contractStatus == 7 || item.contractStatus == 4"
                >
                  <div
                    class="stateGray"
                    v-if="items.dictionaryValue == item.contractStatus"
                  >
                    {{ item.contractStatusVal }}
                  </div>
                </div>
              </div>
              <!-- 合同信息 -->
              <div class="addressInfo">
                {{ item.houseAddress }}
              </div>
              <div class="listInfo">
                <div class="rent">
                  <p class="pOne">租金</p>
                  <p class="pTwo">{{ item.rentMoney }}元/月</p>
                </div>
                <div class="pay">
                  <p class="pOne">支付</p>
                  <p class="pTwo">{{ item.payTypeName }}</p>
                </div>
                <div class="cash" v-if="type == 1">
                  <p class="pOne">
                    {{ item.contractStatus == '预定' ? '定金' : '押金' }}
                  </p>
                  <p class="pTwo">{{ item.depositMoney }}元</p>
                </div>
                <div class=" tenancy">
                  <p class="pOne">租期</p>
                  <p class="pTwo" v-if="item.rentTimeLong != undefined">
                    {{ item.rentTimeLong }}个月
                  </p>
                  <p class="pTwo" v-if="item.contractYears != undefined">
                    {{ item.contractYears }}年
                  </p>
                </div>
                <div class="ruleOne" v-if="type == 1"></div>
                <div class="ruleTwo" v-if="type == 1"></div>
                <div class="ruleOneyz" v-if="type == 2"></div>
                <div class="ruleTwoyz" v-if="type == 2"></div>
                <div class="ruleThree" v-if="type == 1"></div>
              </div>
              <!--                分割线-->
              <div class="ruleFour"></div>

              <div class="contractNum">
                <div class="pOne" v-if="item.contractStatus == '5'">
                  <div>签约日期：{{ item.addTime }}</div>
                  <div>合同截止时间：{{ item.contractEndTime }}</div>
                </div>

                <div class="pOne" v-if="item.contractStatus == '预定'">
                  <div>预定日期：{{ item.addTime }}</div>
                  <div>定金失效时间：{{ item.contractEndTime }}</div>
                </div>

                <!-- 合同号 -->
                <div
                  class="pOne"
                  v-if="
                    item.contractStatus != '5' && item.contractStatus != '预定'
                  "
                >
                  <div>合同编号：{{ item.contractCode }}</div>
                  <span
                    >签约时间：{{ item.contractBeginTime }} 至
                    {{ item.contractEndTime }}</span
                  >
                </div>
              </div>

              <div class="ruleFive"></div>
            </div>

            <div
              class=""
              v-if="
                (item.isUserComfirmProperty == undefined &&
                  item.contractStatus == '4') ||
                  (item.isUserComfirmProperty == undefined &&
                    item.contractStatus == '7')
              "
            ></div>

            <div v-else class="floor" @click="qq(item.isUserComfirmProperty)" >
              <!-- 预定 -->
              <div
                class="cancel"
                v-if="item.contractStatus == '预定'"
                @click="cancelReserveContract(item)"
              >
                <img
                  class="cancelImg"
                  src="..\..\..\assets\images\MyContract-cancelImg.png"
                />
                <span class="pOne">取消预定</span>
              </div>
<!--              <div-->
<!--                class="cancel"-->
<!--                v-if="item.contractStatus == '5'"-->
<!--                @click="dialog(item)"-->
<!--              >-->
<!--                <img-->
<!--                  class="cancelImg"-->
<!--                  src="..\..\..\assets\images\MyContract-cancelImg.png"-->
<!--                />-->
<!--                <span class="pOne">取消预定</span>-->
<!--              </div>-->


              <div
                v-if="
                  item.tempContractPropertyId == undefined &&
                    item.tempIsUserComfirmProperty == undefined
                "
              >
                <div
                  @click="sureProperty({ item })"
                  class="sureProperty"
                  v-if="
                    item.contractPropertyId != undefined &&
                      item.isUserComfirmProperty == '0'
                  "
                >
                  <button>
                    确认物业交割
                  </button>
                </div>
                <div
                  @click="sureProperty({ item })"
                  class="viewProperty"
                  v-if="
                    item.contractPropertyId != undefined &&
                      item.isUserComfirmProperty == '1'
                  "
                >
                  <button>查看物业交割单</button>
                </div>
              </div>
              <div
                v-if="
                  item.tempContractPropertyId != undefined &&
                    item.tempIsUserComfirmProperty != undefined
                "
              >
                <div
                  @click="sureProperty({ item })"
                  class="sureProperty"
                  v-if="
                    item.tempContractPropertyId != undefined &&
                      item.tempIsUserComfirmProperty == '0'
                  "
                >
                  <button>确认物业交割</button>
                </div>
                <div
                  @click="sureProperty({ item })"
                  class="viewProperty"
                  v-if="
                    item.tempContractPropertyId != undefined &&
                      item.tempIsUserComfirmProperty == '1'
                  "
                >
                  <button>查看物业交割单</button>
                </div>
              </div>

              <div
                @click="changeLease({ item })"
                class="changeLease"
                v-if="
                  item.contracthistoryId == undefined &&
                    item.approvalTypeStatus == '1'
                "
              >
                <!--<button>变更租约</button>-->
              </div>

              <div
                class="telle"
                v-if="
                  item.contractStatus != '4' &&
                    item.contractStatus != '7' &&
                    // item.contractStatus != '5' &&
                    item.contractStatus != '预定'
                "
              >
                <img class="telImg" src="..\..\..\assets\images\tel.png" />
                <span class="telTex" @click="showMobile(item)">联系管家</span>
<!--                正常的联系管家-->
              </div>

              <div class="tel" v-if="item.contractStatus == '预定'">
                <img class="telImg" src="..\..\..\assets\images\tel.png" />
                <span class="telTex" @click="showMobile(item)">联系管家</span>
<!--                预定的联系管家-->
              </div>
<!--              <div-->
<!--                class="tel"-->
<!--                v-if="-->
<!--                  item.contractStatus != '4' &&-->
<!--                    item.contractStatus != '7' &&-->
<!--                    item.contractStatus == '5'-->
<!--                "-->
<!--              >-->
<!--                <img class="telImg" src="..\..\..\assets\images\tel.png" />-->
<!--                <span class="telTex" @click="showMobile(item)">联系管家2</span>-->
<!--              status==5时右侧的联系管家，但是==5是，不需要取消预订，所以不需要这个联系管家-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <div style="width: 100%;height: 0.4rem;"></div>
      </van-list>

      <van-list v-if="condition==3"
          v-model="loading"
          :finished="finished"
          :finished-text="noContent ? '' : ''"
          @load="onLoad"
      >
        <div v-for="(item,index) in contract" :key="index" class="circulation">
          <div class="circulationList">
            <div @click="viewChangeContractDetail(item)">
              <div :class="colorMap[item.status]">
                {{ item.statusStr }}
              </div>
              <div class="addressInfo">
                {{ item.houseAddress }}
              </div>
              <div class="listInfo">
                <div class="rent">
                  <p class="pOne">变更原因</p>
                  <p class="pTwo">{{ item.reasonDictionaryTitle }}</p>
                </div>
                <div class="pay">
                  <p class="pOne">合同编号</p>
                  <p class="pTwo">{{ item.contractCode }}</p>
                </div>
                <div class="ruleTwo"></div>

              </div>
              <div class="ruleFive"></div>
            </div>
            <div class="floor" v-if="item.status==='4'&&item.electricContractPath">
              <div
                  @click="signChangeContract( item )"
                  class="sureProperty"
              >
                <button>签字</button>
              </div>
            </div>
          </div>
        </div>
      </van-list>


      <!--拨打电话弹窗-->
      <dial-mobile
        :ownerMobile="temporaryOwnerMobile"
        :userName="temporaryOwnerName"
        :dialogTitle="dialogTitle"
        :mobileShow="isMobileShow"
        @hideDialog="hideDialog"
      >
      </dial-mobile>
    </div>
    <div v-if="noContent == true" style="margin-top: 100px;">
      <common-empty></common-empty>
    </div>
  </div>
</template>

<script>
import {
  contractList,
  contractListWithPercontract,
  queryBaseData,
  contractInvalid,
  queryContractProperty,
  cancelReserveContract,
  getSignPath,
} from '../../../getData/getData'
import { NavBar, List, Dialog } from 'vant'
import {
  globaluserId,
  responseUtil,
  getStaffId,
} from '../../../libs/rongxunUtil'
import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'
import rxNavBar from '../../../components/rongxun/rx-navBar/navBar'
export default {
  components: {
    [NavBar.name]: NavBar,
    [List.name]: List,
    [Dialog.name]: Dialog,
    rxNavBar,
    dialMobile,
  },
  data() {
    return {
      condition: 1, //用来控制两种状态的切换条件
      noContent: false,
      dialogTitle: '联系管家',
      //拨打电话弹窗
      isMobileShow: false,
      temporaryOwnerMobile: '',
      temporaryOwnerName: '',
      page: 1, //初始页数
      loading: false,
      finished: false,
      contract: [], //合同信息
      contractStatusMap: [], //状态
      type: 1, //类型 1 租客合同 2 业主合同 3变更协议
      circulationListState: 'circulationList',
      circulationListStateGray: 'circulationListGray',
      change: true,
      last: true,
      potClient: true,
      priClient: false,
      contractId: '',
      colorMap:{
        //1待审核  2驳回  3已作废
        //4待签字  5已签字  6已处理
        '1': 'stateBlue',
        '2': 'stateRed',
        '4': 'stateOrange',
        '5': 'stateGreen',
        '6': 'stateGreen',
      }
      // changeContractList:[
      //   {
      //     contactId:24764,
      //     contractCode:'S240500009',
      //     statusStr:'待审批',
      //     contractType:'1',
      //     contractStatusStr:'租期中',
      //     contractTypeStr:'业主合同',
      //     houseAddress:'维也纳艺术学院-Schillerplatz3号2单元303号',
      //     reasonDictionaryTitle:'金额变更',
      //     editDate:"2024-06-17",
      //
      //   }
      // ],
    }
  },
  mounted() {
    // this.onLoad();
    this.initChooseTypes()
    //取出localStorage里的type，用于判断之前显示的是租客or业主
    let type = JSON.parse(localStorage.getItem('type'))
    if (type == '1') {
      this.changeClientOne()
    } else if (type == '2') {
      this.changeClientTwo()
    } else if (type == '3') {
      this.changeClientThree()
    }
  },
  beforeRouteEnter(to, from, next) {
    //清除本地localStorage缓存
    if (from.name == null) {
      localStorage.removeItem('currentLabel')
      localStorage.removeItem('type')
      localStorage.removeItem('inputValue')
      localStorage.removeItem('currentLabelPublic')
    }
    next()
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'contractDetail') {
      from.meta.keepAlive = false
    } else {
      from.meta.keepAlive = false
    }
    next()
  },
  methods: {
    dialog(item) {
      Dialog.confirm({
        title: '友情提示',
        message: '确定删除吗？',
      })
        .then(() => {
          // on confirm
          let that = this
          let initData = {}
          initData.contractId = item.contractId
          contractInvalid(initData).then(response => {
            responseUtil.dealResponse(that, response, () => {
              if (response.data.code == 0) {
                that.changeClientOne()
                responseUtil.alertMsg(that, response.data.msg)
              }
            })
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    cancelReserveContract(item) {
      Dialog.confirm({
        title: '友情提示',
        message: '确定取消预定吗？',
      })
        .then(() => {
          // on confirm
          let that = this
          let initData = {}
          initData.perContractId = item.id
          cancelReserveContract(initData).then(response => {
            responseUtil.dealResponse(that, response, () => that.onLoad())
          })
        })
        .catch(() => {
          // on cancel
        })
    },

    //下拉加载
    onLoad: function() {
      let that = this

      let contractinitData = {}
      contractinitData.type = that.type
      contractinitData.currentPage = that.page + ''
      contractinitData.numberPage = '3'
      contractinitData.flagType = '2' //20210127 添加逻辑，区分我的合同和工作台合同列表，方
      contractinitData.globaluserId = globaluserId()
      // contractinitData.userid = globaluserId()
      //console.log(that.type+"that.type")
      //contractListWithPercontract(contractinitData).then(function(response) {
      contractListWithPercontract(contractinitData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          // console.log(response.data.data.data.length+"！！")
          //console.log(response.data.data)
          if (
            response.data.data.pageCount == 0 ||
            response.data.data.pageCount == undefined
          ) {
            that.noContent = true
            that.finished = true
          } else {
            that.noContent = false
          }
          if (response.data.data.data) {
            //console.log(response.data.data.data.length)
            var count = response.data.data.pageCount
            let list = response.data.data.data

            if (list) {
              if (that.type == 1) {
                that.contract.splice(0, that.contract.length)
                for (let i = 0; i < list.length; i++) {
                  that.contract.push(list[i])
                }
              } else {
                for (let i = 0; i < list.length; i++) {
                  that.contract.push(list[i])
                }
              }
            }
            that.loading = false
            // 数据全部加载完成
            if (that.contract.length >= count) {
              that.finished = true
            }
            // if (1 == that.page && !list) {
            //     that.noContent = true
            // } else {
            //     that.noContent = false
            // }
            that.page++
          } else {
            that.contract.splice(0, that.contract.length)
          }
          //   console.log(response.data.data)
        })
      })
    },
    //初始化合同状态
    initChooseTypes: function() {
      let that = this
      let initData = {}
      initData.fdName = ['CONTRACTSTATUSMAP']
      queryBaseData(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.contractStatusMap = response.data.data.CONTRACTSTATUSMAP
          // console.log(that.contractStatusMap)
        })
      })
    },
    //callPhone
    callPhone() {
      this.$router.push({
        name: 'callPhone',
        query: {},
      })
    },
    qq(e) {
      console.log(e)
    },
    showOwner(e) {
      let that = this
      //预定合同
      if (e.item.contractStatus == '预定') {
        this.$router.push({
          name: 'perContractDetail',
          query: {
            item: e.item,
            type: that.type,
          },
        })
        return
      }
      let managePhone = ''
      let manageName = ''
      if (that.type == 1) {
        that.contractId = e.item.contract_id
        that.ownerOrRenterContractId = e.item.rentercontract_id
        managePhone = e.item.renterStfMobile
        manageName = e.item.staffName
      } else {
        that.contractId = e.item.contractId
        that.ownerOrRenterContractId = e.item.ownerContractId
        managePhone = e.item.ownerStfMobile
        manageName = e.item.staffName
      }
      that.historyType = e.item.contractHistoryHistoryTypeVal
      that.contractTypeVal = e.item.contractStatusVal
      that.contractStatus = e.item.contractStatus
      this.$router.push({
        name: 'contractDetail',
        query: {
          contractId: that.contractId, //合同id
          ownerOrRenterContractId: that.ownerOrRenterContractId, //业主or租客 合同id
          type: that.type, //租客1 业主2
          historyType: that.historyType, //新签
          contractStatus: that.contractStatus, //合同状态值
          contractTypeVal: that.contractTypeVal, //合同状态 在组中 代签字 已解除 已作废
          managePhone: managePhone,
          manageName: manageName,
          houseType:e.item.houseType,
        },
      })
    },

    //变更租约
    changeLease(e) {
      let that = this
      this.contractId = e.item.contractId
      //console.log(e.item.approvalTypeStatus+"123.321")
      // console.log(this.contractId)
      //租客
      if (that.type == 1) {
        that.id = e.item.roomHouse_id
        that.ownerOrRenterContractId = e.item.rentercontract_id
      } else {
        //业主
        that.id = e.item.setHouse_id
        that.ownerOrRenterContractId = e.item.ownerContractId
      }
      // console.log(e)
      // console.log(e.item.contract_id)
      this.$router.push({
        name: 'changeLeaseApply',
        query: {
          id: that.id,
          type: that.type,
          mode: '0', //mode 0我的 1工作台
          ownerOrRenterContractId: that.ownerOrRenterContractId, //（业主合同or租客合同）id
          contractId: that.contractId, //合同id
        },
      })
    },

    //确认物业交割
    sureProperty(e) {
      let that = this
      if (that.type == 1) {
        that.contract_id = e.item.contract_id
      } else {
        that.contract_id = e.item.contractId
      }
      let contractPropertyId = ''
      if (
        e.item.tempContractPropertyId != undefined &&
        e.item.tempIsUserComfirmProperty != undefined
      ) {
        contractPropertyId = e.item.tempContractPropertyId
      }
      // console.log(that.contract_id)
      this.$router.push({
        name: 'propertyDelivery',
        query: {
          contract_id: that.contract_id,
          condition:that.condition===1?true:false,
          contractPropertyId: contractPropertyId,
          houseType:e.item.houseType,
        },
      })
    },

    //确认物业交割
    viewProperty() {
      this.$router.push({
        name: '',
        query: {},
      })
    },
    //拨打电话
    showMobile(e) {
      let that = this
      if (this.type == 1) {
        that.temporaryOwnerMobile = e.renterStfMobile
        that.temporaryOwnerName = e.staffName
      } else {
        that.temporaryOwnerMobile = e.ownerStfMobile
        that.temporaryOwnerName = e.staffName
      }

      that.isMobileShow = !that.isMobileShow
    },
    //关闭拨打电话弹窗
    hideDialog() {
      let that = this
      that.isMobileShow = false
    },
    leftReturn() {
      this.$router.go(-1)
    },
    changeClientOne() {
      localStorage.setItem('type', JSON.stringify(1))
      this.loading = false
      this.finished = false
      this.contract = []
      this.last = true
      this.type = 1
      this.page = 1
      this.loading = true
      this.noContent = false
      this.potClient = true
      this.priClient = false
      this.onLoad()
      this.condition = 1
    },
    changeClientTwo() {
      localStorage.setItem('type', JSON.stringify(2))
      this.condition = 2
      this.loading = false
      this.finished = false
      this.contract = []
      this.last = false
      this.type = 2
      this.page = 1
      this.loading = true
      this.noContent = false
      this.potClient = false
      this.priClient = true
      this.onLoad()
    },
    changeClientThree(){
      localStorage.setItem('type', JSON.stringify(3))
      this.condition = 3
      this.loading = false
      this.finished = false
      this.contract = []
      this.last = false
      this.type = 3
      this.page = 1
      this.loading = true
      this.noContent = false
      this.potClient = false
      this.priClient = true
      this.onLoad()
    },
    viewChangeContractDetail(item){
      this.$router.push({
        name: 'changeContractDetail',
        query:item
      })
    },
    signChangeContract(item){
      console.log()
      let that = this
      let initData = {}
      initData.contractEditId = item.contractEditId
      getSignPath(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data.path)
          that.$router.push({
            name:'electronicContract',
            query:{
              type:'ssqian',
              title:'电子合同',
              electronicContractUrl: response.data.data.path,
            }
          });
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.myContract {
  margin-bottom: 30px;
  width: 100%;
  //overflow: hidden;
}

.header {
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 8;
  background-color: #f8f8f8;
}

.hTitle {
  width: 100%;
  position: relative;
  top: 135px;
  background-color: #f8f8f8;
  margin-top: -14px;
}

//租客和业主合同的切换
.clientChange {
  position: relative;
  width: 100%;
  height: 57px;
  background-color: #ffffff;
  /*align-items: center;*/
  font-size: 14px;
  margin-top: -3px;
  z-index: 2;
}

.clientTitle {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}

/*私客和公客转换使用 色变红*/
.potClientOne {
  color: #ff5d3b;
  font-weight: bold;
}

/*私客和公客转换使用，色变红*/
.priClientOne {
  font-weight: bold;
  color: #ff5d3b;
}

/*私客和公客转换使用 色变红*/
.potClientTwo {
  color: #999;
}

/*私客和公客转换使用，色变红*/
.priClientTwo {
  color: #999;
}

/*私客和公客转换使用，移动下方小横杠*/
.lasta {
  width: 17px;
  position: absolute;
  top: 45.5px;
  left: 80px;
  background-color: #ff5d3b;
  border: 2px solid #ff5d3b;
  border-radius: 10px;
}

/*私客和公客转换使用，移动下方小横杠*/
.lastb {
  width: 17px;
  position: absolute;
  top: 45.5px;
  left: 270px;
  background-color: #ff5d3b;
  border: 2px solid #ff5d3b;
  border-radius: 10px;
}

.su {
  position: absolute;
  left: 193px;
  color: #f8f8f8;
}

/*    占位的DIV*/
/*    .placeholderDiv{*/
/*        height: 150px;*/
/*        border: 1px solid red;*/
/*    }*/
.pOne {
  color: #999;
  font-size: 13px;
  font-family: PingFangSC-Regular;
}

.pTwo {
  color: #ff5d3b;
  font-size: 13px;
  font-family: Oswald-Regular;
}

/*    循环主体DIV*/
.circulation {
  width: 100%;
}

/*    循环列表*/
.circulationList {
  background-color: #fff;
  width: 92%;
  border-radius: 8px;
  margin: 14px auto 0;
  // padding-bottom: 5px;

  .stateGreen,
  .stateRed,
  .stateGray,
  .stateBlue,
  .stateOrange {
    font-size: 10px;
    width: 70px;
    height: 16px;
    line-height: 17.15px;
    color: #fff;
    border-radius: 8px 0;
    text-align: center;
  }

  .stateGreen {
    background-image: linear-gradient(to right, #66cb94, #28d3b0);
  }

  .stateRed {
    background-image: linear-gradient(to right, #e02020, #ff431b);
  }

  .stateGray {
    background-image: linear-gradient(to right, #999999, #999999);
  }

  .stateOrange {
    background-image: linear-gradient(to right, #ffc274, #ff5d3b);
  }

  .stateBlue {
    background-image: linear-gradient(to right, #7FCAFF, #3091FF);
  }

  /*地址*/
  .addressInfo {
    height: 50px;
    font-size: 15px;
    font-weight: bold;
    width: 92%;
    margin: 0 auto;
    line-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /*主体循环信息*/
  .listInfo {
    width: 92%;
    margin: 0 auto 22px;
    display: flex;
    position: relative;

    /*租金信息*/
    .rent,
    .pay,
    .cash,
    .tenancy {
      line-height: 28px;
      flex: 1;
      text-align: center;
    }

    /*    支付信息*/
    /*    押金信息*/
    /*    租期*/
  }

  /*    分割线*/
  .ruleFour,
  .ruleFive {
    border: 0.5px solid #f8f8f8;
    width: 100%;
    margin-top: 15px;
  }

  .ruleFour {
    margin-bottom: 15px;
  }

  .ruleFive {
    // margin-bottom: 5px;
  }

  /*合同编号*/
  .contractNum {
    margin-left: 20px;
    height: 48px;
    line-height: 24px;
    width: 100%;
  }

  /*    底部DIV*/
  .floor {
    width: 92%;
    margin: 0 auto;
    height: 50px;
    // display: flex;
    // align-items: center;

    .tel {
      float: right;
      height: 50px;
      // flex: 1;

      .telTex {
        font-size: 12px;
        color: #666;
      }

      .telImg {
        height: 20px;
        width: 20px;
        margin-right: 3px;
      }
    }

    .telle {
      float: left;
      // flex: 1;

      .telTex {
        font-size: 12px;
        color: #666;
      }

      .telImg {
        height: 20px;
        width: 20px;
        margin-right: 3px;
      }
    }

    .changeLease {
      float: right;
      flex: 1;
      margin-left: 45px;
      display: flex;
      align-items: center;
      height: 50px;
      font-size: 12px;

      button {
        background-image: linear-gradient(to right, #7fcaff, #3091ff);
        width: 80px;
      }
    }

    .sureProperty {
      margin-left: 10px;
      float: right;
      display: flex;
      align-items: center;
      height: 50px;
      font-size: 12px;

      button {
        width: 90px;
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
      }
    }

    .viewProperty {
      // margin-left: 217px;
      float: right;
      margin-left: 10px;
      display: flex;
      align-items: center;
      height: 50px;
      font-size: 12px;
      button {
        width: 100px;
        background-image: linear-gradient(white, white),
          linear-gradient(to right, #ffc274, #ff5d3b);
        border: 1px solid transparent;
        background-clip: padding-box, border-box;
        background-origin: border-box;
        color: #ff5d3b;
      }
    }

    .cancel {
      // flex: 3.3;
      float: left;
      display: flex;
      align-items: center;
      height: 50px;
      font-size: 12px;
      margin-right: 20px;

      .cancelImg {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
    }

    .tel,
    .telle {
      display: flex;
      align-items: center;
      height: 50px;
      font-size: 12px;
    }

    button {
      font-size: 12px;
      height: 30px;
      border-radius: 8px;
      border: none;
      color: #ffffff;
    }
  }

  .ruleOne,
  .ruleTwo,
  .ruleThree,
  .ruleOneyz,
  .ruleTwoyz {
    position: absolute;
    height: 40px;
    border: 0.5px solid #f8f8f8;
    top: 8px;
  }

  .ruleOne {
    left: 78px;
  }

  .ruleTwo {
    left: 162px;
  }

  .ruleOneyz {
    left: 108px;
  }

  .ruleTwoyz {
    left: 212px;
  }

  .ruleThree {
    left: 236px;
  }
}

.circulationListGray,
.circulationListGray p {
  color: #9d9d9d !important;
}

.choose {
  width: 100%;
  height: auto;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.choose-one {
  //width: 187px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
}

.choose-one-textActive {
  //width: 60px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  //margin-top: 24px;
  //margin-bottom: 10px;
  margin: 10px auto;

  //margin-left: 63px;
  color: rgba(255, 93, 59, 1);
  text-align: center;
  font-family: PingFangSC-Semibold;
}

.choose-one-text {
  //width: 60px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  margin: 10px auto;
  //margin-top: 24px;
  //margin-bottom: 10px;
  //margin-left: 65px;
  color: rgba(153, 153, 153, 1);
  text-align: center;
  font-family: PingFangSC-Semibold;
}

.choose-one-icon {
  width: 20px;
  height: 4px;
  border-radius: 3px;
  background-color: rgba(255, 93, 59, 1);
  //margin: 5px 0 15px 83px;
}

.choose-line {
  width: 2px;
  height: 17px;
  //margin-top: 23px;
  background-color: rgba(239, 239, 239, 1);
}
</style>
